import styled from 'styled-components';

import { useMemo } from "react";
import {
  BadgeError,
  BadgeSuccess,
  formatAsDateAndTime,
  SmallText,
  Text
} from '../../components/Common';
import { DeviceImage } from '../../components/device/DeviceImage';
import { Order, OrderChannel } from '../../models/Order';

const OrderRowContainer = styled.div`
  display: flex;
  padding: 10px 5px 10px 5px;
  align-items: center;
  &:first-child {
    border-top: #d8d8d8 1px solid;
  }
  border-bottom: #d8d8d8 1px solid;
  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
`;

const OrderTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OrderTags = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const OrderInfoMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-start;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 5px;
`;

const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AMAZON_MARKETPLACES: Record<string, string> = {
  // UK FBA
  "A1F83G8C2ARO7P": "🇬🇧 (FBA)",

  // UK MCF
  "AZMDEXL2RVFNN": "🇬🇧 (MCF)",

  // US FBA
  "ATVPDKIKX0DER": "🇺🇸 (FBA)",
};

const describeMarketplace = (channel: OrderChannel, marketplaceId: string) => {
  if (channel === 'amazon') {
    const marketplace = AMAZON_MARKETPLACES[marketplaceId];
    if (marketplace) {
      return marketplace;
    }
  }

  return '';
}

export const OrderRow = ({ order }: { order: Order }) => {
  const itemsDescription = useMemo(() => {
    return order.order_items.map(item => `${item.sku} x ${item.quantity}`).join(', ');
  }, [order.order_items]);

  return (
    <OrderRowContainer>
      <DeviceImage deviceModel={order.order_items[0].sku} size={60} />
      <OrderInfoMain>
        <OrderTitleRow>
          <Text><strong>{order.channel} - {order.channel_order_id}</strong></Text>
        </OrderTitleRow>
        <Text>{itemsDescription}</Text>
        <SpacedRow>
          <OrderTags>
            {order.order_status === 'shipped' ? <BadgeSuccess>{order.order_status}</BadgeSuccess> : <BadgeError>{order.order_status}</BadgeError>}
            {describeMarketplace(order.channel, order.marketplace_id)}
          </OrderTags>
          <SmallText>{formatAsDateAndTime(order.purchase_at)}</SmallText>
        </SpacedRow>
      </OrderInfoMain>
    </OrderRowContainer>
  );
};

