import styled from 'styled-components'

import { Device } from '../../models/Device';
import { firstActiveSubscription } from '../../lib/common';

const DeviceStatusTagContainer = styled.span<{fg: string, bg: string}>`
  background-color: ${props => props.bg};
  color: ${props => props.fg};
  font-weight: 500;
  font-size: 10pt;
  border-radius: 5px;
  padding: 3px 8px;
  margin: 2px;
`;

export const DeviceStatusTag = ({ device }: { device: Device }) => {

  // Tag should include state from both device and current_subscription
  // device STATE is one of:  active, suspended, new
  // subscription state:  is one of active, canceled, past_due   ... and a few other non important ones:
  // .. cancel-pending, expired.incomplete_expired, expired
  
  // If device.state is active we also consider and display past_due subscription state if present. Otherwise just device state.
  // status -> [forgroundColor, backgroundColor]

  const ERROR_COLORS = ['#b3093c', '#ffe7f2'];
  const OTHER_COLORS = ['#4f4f4f', '#dedede'];

  const statusColorMap = {
    'active': ['#006908', '#d7f7c2'],
    'suspended': ERROR_COLORS,
    'new': ['#b3093c', '#ffe7f2'],
    'other': OTHER_COLORS,
  };

  type ObjectKey = keyof typeof statusColorMap;

  const colors = statusColorMap[device.state as ObjectKey] || statusColorMap['other'];

  let pastDueTag = null;
  let cancelSoonTag = null;
  const activeDeviceSubscription = firstActiveSubscription(device.deviceSubscriptions);
  if (activeDeviceSubscription && activeDeviceSubscription.state === 'past_due') {
    const subscriptionState = activeDeviceSubscription.state;
    pastDueTag = <DeviceStatusTagContainer fg={ERROR_COLORS[0]} bg={ERROR_COLORS[1]}>{subscriptionState}</DeviceStatusTagContainer>;
  }

  if (activeDeviceSubscription && activeDeviceSubscription.cancelAt) {
    cancelSoonTag = <DeviceStatusTagContainer fg={OTHER_COLORS[0]} bg={OTHER_COLORS[1]}>{"cancel-pending"}</DeviceStatusTagContainer>;
  } else if(activeDeviceSubscription && activeDeviceSubscription.state === 'trialing') {
    cancelSoonTag = <DeviceStatusTagContainer fg={OTHER_COLORS[0]} bg={OTHER_COLORS[1]}>{"trial"}</DeviceStatusTagContainer>;
  }


  return (
    <span>
      <DeviceStatusTagContainer fg={colors[0]} bg={colors[1]}>{device.state}</DeviceStatusTagContainer>
      {pastDueTag}
      {cancelSoonTag}
    </span>

  );
}