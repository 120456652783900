import { useQuery } from 'react-query';
import styled from 'styled-components';
import ShieldGPSAxiosClient from '../../lib/ShieldGPSAxiosClient';

import { Order } from '../../models/Order';
import { OrdersFeedResponse } from '../../models/Requests';
import { OrderRow } from '../../components/order/OrderRow';

export const Content = styled.div`
  text-align: left;
  padding-left: 50px;
  max-width: 800px; 
  margin: auto;
`;

const fetchOrdersFeed = async (): Promise<Order[]> => {
  const response = await ShieldGPSAxiosClient.get<OrdersFeedResponse>('/admin/orders');
  return response.data;
};


export function OrdersFeedPage() {
  /**
   * 
   */
  const { data, isLoading } = useQuery(
    ['orders-feed'],
    () => fetchOrdersFeed(),
    {},
  );

  return (
    <div>
        <h1>Orders Feed</h1>
        { isLoading ? <>Loading...</> : (
          <Content>
            <ol>
              {data?.map((order) => <OrderRow order={order} />)}
            </ol>
          </Content>
        )}
    </div>
  );
};

