import React, { useState } from 'react';
import styled from 'styled-components'
import {
  useNavigate,
} from "react-router-dom";
import { useQuery } from 'react-query';
import ShieldGPSAxiosClient from '../../lib/ShieldGPSAxiosClient';
import moment from 'moment';
import { User } from '../../models/User';
import { Device, DeviceActivationEvent } from '../../models/Device';

import { 
  MainContainer,
} from '../../components/Common';
import { ActivationsFeedResponse } from '../../models/Requests';
import { DeviceRow } from '../../components/device/DeviceRow';

export const Content = styled.div`
  text-align: left;
  padding-left: 50px;
  max-width: 800px; 
  margin: auto;
`;

const fetchActivationsFeed = async (): Promise<DeviceActivationEvent[]> => {
  const response = await ShieldGPSAxiosClient.get<ActivationsFeedResponse>('/admin/user-search/activations-feed?event_type=activated');
  return response.data.events;
};


export function ActivationsFeedPage() {
  /**
   * 
   */
  const { data, isLoading } = useQuery(
    ['activations-feed'],
    () => fetchActivationsFeed(),
    {},
  );

  return (
    <div>
        <h1>Activations Feed</h1>
        { isLoading ? <>Loading...</> : (
          <Content>
            <ol>
              {data?.map((event) => <DeviceRow device={event.device} />)}
            </ol>
          </Content>
        )}
    </div>
  );
};

