import moment from 'moment';
import styled, { css } from 'styled-components';
import { Device, DeviceCommand, DeviceEvent, DeviceSubscription, GetDeviceEventsResponse } from '../models/Device';

import CircularProgress from '@mui/material/CircularProgress';


// Icons

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import SubscriptionsRoundedIcon from '@mui/icons-material/SubscriptionsRounded';
import TaxiAlertOutlinedIcon from '@mui/icons-material/TaxiAlertOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { Paper, Typography } from '@mui/material';
import axios from "axios";
import React, { ReactNode, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Bar, BarChart, Line, LineChart } from 'recharts';
import ShieldGPSAxiosClient from '../lib/ShieldGPSAxiosClient';

/**
 * <MainContainer>
 *   <LeftColumn>
 *   </LeftColumn>
 * 
 *   ...
 * 
 *   <RightColumn>
 *   </RightColumn>
 * </MainContainer>
 */
export const MainContainer = styled.div`
  display: flex;
  padding: 30px;
  justify-content: center;
`;

export const LeftColumn = styled.div`
  flex-grow: 1;
  max-width: 320px;
  min-width: 240px;
  text-align: left;
`;

export const RightColumn = styled.div`
  flex-grow: 2;
  text-align: left;
  padding-left: 50px;
  max-width: 800px; 
`;

export const Text = styled.span`
  display: block;
  color: #414552;
`;

export const SmallText = styled(Text)`
  font-size: 10pt;
`;

export const BiggestText = styled(Text)`
  font-weight: 500;
  font-size: 24pt;
  margin-bottom: 5px;
`;

export const SectionHeaderText = styled(Text)`
  font-weight: 600;
  font-size: 16pt;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FieldNameText = styled(Text)`
  color: #687385;
  padding-bottom: 4px;
`;

export const Box = styled.div`
  padding: 10px;
  margin-bottom: 30px;
`;

export const FieldDescriptionContainer = styled.div`
  margin-bottom: 20px;
`;

export const AlarmTag = styled.span`
  background-color: rgb(253, 237, 237);
  color: rgb(95, 33, 32);
  padding: 4px;
  border-radius: 4px;
  font-size: 10pt;
  display: inline-flex;
  gap: 6px;
`;

const DeviceCommandBlock = styled.div`
  font-family: "Roboto Mono", "Lucida Console", Monaco, monospace;
  font-size: 0.7rem;
`;

const FixedWidth = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  align-items: flex-end;
  font-size: 0.7rem;
  color: #7b7b7b;
  gap: 2px;
  padding: 4px 2px;
`;

const ClickToCopyContainer = styled.div`
  display: flex;
  gap: 6px;
`;

export const Badge = styled.span`
  background-color: #dedede;
  color: #4f4f4f;
  border-radius: 5px;
  padding: 3px 8px;
  margin: 2px;
  font-weight: 500;
  font-size: 10pt;
`;

export const BadgeSuccess = styled(Badge)`
  background-color: #d7f7c2;
  color: #006908;
`;

export const BadgeError = styled(Badge)`
  background-color: #fde7e7;
  color: #a61d24;
`;


export const FieldDescription = ({ name, value, children }: { name: string; value?: string, children?: any }) => {
  return (
    <FieldDescriptionContainer>
      <FieldNameText>{name}</FieldNameText>
      {children || (
        <Text>{value || '--'}</Text>
      )}
    </FieldDescriptionContainer>
  );
};

const ImageContainer = styled.div<{ size: string }>`
  width: ${props => props.size};
  height: ${props => props.size};
  margin-right: 20px;

`;

export const Image = ({ image, size, alt }: { image: any, size: number, alt: string }) => {
  return (
    <ImageContainer size={`${size}px`}>
      <img width="100%" src={image} alt={alt} />
    </ImageContainer>
  );
}

const SubscriptionStateTag = styled.span`
  background-color: #dedede;
  color: #4f4f4f;
  border-radius: 5px;
  padding: 3px 8px;
  margin: 2px;
  font-weight: 500;
  font-size: 10pt;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-start;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 5px;
`;

const DefaultRow = styled.div<{ allowHover?: boolean }>`
  display: flex;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 5px;
  align-items: center;
  &:first-child {
    border-top: #d8d8d8 1px solid;
  }
  border-bottom: #d8d8d8 1px solid;
  ${props => props.allowHover && css`
    &:hover {
      background-color: #eaeaea;
      cursor: pointer;
    }
  `}
`;

const CondensedRow = styled(DefaultRow) <{ clickable?: boolean }>`
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;

  ${({ clickable }) =>
    clickable &&
    `
    cursor: pointer;
    &:hover {
      background-color: lightgray; /* Light gray background on hover */
    }
  `}
`;

const StatusIndicatorContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const CommandStatusIcons = styled.div`
  display: flex;
`;

export const StatusIndicator = ({ label, isSuccess }: { label: string, isSuccess: boolean }) => {
  return (
    <StatusIndicatorContainer>
      {label}
      {isSuccess ? (
        <CheckCircleRoundedIcon fontSize='small' sx={{ color: 'green' }} />
      ) : (
        <ErrorRoundedIcon fontSize='small' sx={{ color: 'red' }} />
      )}
    </StatusIndicatorContainer>
  );
}

interface DeviceSubscriptionSummaryProps {
  subscription: DeviceSubscription;
  reactivateDisabled?: boolean;
  onReactivate?: () => void;
  onCancel?: () => void;
  onClick?: () => void;
}


/**
 * Subscription name, interval, price, creation date
 * Actions:
 *  - Cancel
 *  - Reactivate (when not an active subscription, when showReactivate flag set, change one day)
 * @param param0 
 * @returns 
 */
export const DeviceSubscriptionSummary = ({ subscription, reactivateDisabled, onReactivate, onCancel, onClick }: DeviceSubscriptionSummaryProps) => {
  return (
    <DefaultRow allowHover={!!onClick}>
      <div style={{ padding: '10px', paddingRight: '20px' }}>
        <SubscriptionsRoundedIcon color="primary" fontSize="large" />
      </div>
      <SummaryContainer>
        <Text>
          <strong>{subscription.plan.name}</strong>
        </Text>
        <Text>
          {subscription.plan.price} {subscription.plan.currency} • Billed {subscription.plan.interval}
        </Text>
        <SmallText>
          {formatAsDate(subscription.createdAt)} → {subscription.canceledAt ? formatAsDate(subscription.canceledAt) : 'Now'}
        </SmallText>
        <div>
          <SubscriptionStateTag>
            {subscription.state}
          </SubscriptionStateTag>
        </div>
        {['active', 'trialing'].includes(subscription.state) && !!subscription.cancelAt && (
          <div>
            <AlarmTag>
              <AccessTimeFilledRoundedIcon sx={{ fontSize: 15 }} /> Will auto cancel {formatAsFromNow(subscription.cancelAt)}
            </AlarmTag>
          </div>
        )}
      </SummaryContainer>
      <div>
        {!!onReactivate && (
          <Tooltip title="Reactivate">
            <span>
              <IconButton color="primary" disabled={reactivateDisabled} onClick={onReactivate}>
                <PlayArrowRoundedIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {!!onCancel && (
          <Tooltip title="Cancel">
            <IconButton color="primary" onClick={onCancel}>
              <ClearRoundedIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="View in Stripe">
          <IconButton
            onClick={() => window.open(`https://dashboard.stripe.com/subscriptions/${subscription.stripeId}`, "_blank")}>
            <OpenInNewRoundedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    </DefaultRow>
  );
};

export const capitalizeFirstLetter = (value: string | null | undefined): string | null => {
  if (!value) {
    return null;
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
}

export const formatAsFromNow = (time: string | Date | undefined): string | undefined =>
  time ? moment(time).fromNow() : undefined;

export const formatAsDate = (time: string | Date | undefined): string | undefined =>
  time ? moment(time).format('Do MMM YYYY') : undefined;

export const formatAsDateAndTime = (time: string | Date | undefined): string | undefined =>
  time ? moment(time).format('Do MMM YYYY @ hh:mm A zz') : undefined;

export const formatAsTime = (time: string | Date | undefined): string | undefined =>
  time ? moment(time).format('hh:mm A zz') : undefined;


type UrlTemplateFunc = (_: string) => string;

const SIM_PROVIDER_ICCID_URLS: Record<string, UrlTemplateFunc> = {
  'one_nce': (iccid: string) => `https://portal.1nce.com/portal/customer/sims?iccid=${iccid}`,
  'twilio': (_: string) => `https://console.twilio.com/us1/develop/iot/wireless-sim/sims?frameUrl=%2Fconsole%2Fwireless%2Fsims%3Fx-target-region%3Dus1`,
};

/**
 * Link to SIM provider status page.
 * 
 * @param param0 
 * @returns 
 */
export const DeviceICCIDLink = ({ device }: { device: Device | undefined | null }) => {
  if (!device || !device.simProvider || !device.iccid) {
    return <span>--</span>;
  }

  const iccidUrlTemplateFn: UrlTemplateFunc = SIM_PROVIDER_ICCID_URLS[device?.simProvider];
  if (!iccidUrlTemplateFn) {
    return <span>{device.iccid}</span>;
  }

  return (
    <ClickToCopy copyContent={device.iccid}>
      <a href={iccidUrlTemplateFn(device.iccid)} target="_blank" rel="noopener noreferrer">{device.iccid}</a>
    </ClickToCopy>
  );
}

interface ClickToCopyProps {
  copyContent: string;
  children?: ReactNode;
}

/**
 * Click to copy text.
 * @param param0 
 * @returns 
 */
export const ClickToCopy: React.FC<ClickToCopyProps> = ({ copyContent, children }) => {
  return (
    <ClickToCopyContainer>
      {children}
      <CopyButton content={copyContent} />
    </ClickToCopyContainer>
  );
};

/**
 * Coppy some text to clipboard when clicked.
 * @param param0 
 * @returns 
 */
export const CopyButton: React.FC<{ content: string }> = ({ content }) => {

  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(content);
    } catch (e) {
      console.error('Failed to copy text to clipboard', e);
    }
  };

  return (
    <IconButton
      color="default"
      onClick={copyText}
      sx={{
        padding: '0px',
        marginLeft: '0px',
        cursor: 'pointer',
        color: 'default',
        '&:hover': {
          color: 'blue', // Change this to the color of your choice
        }
      }}>
      <ContentCopyOutlinedIcon fontSize='small' />
    </IconButton>
  )
};

export const DeviceStatusTinyBarChart = ({ deviceImei }: { deviceImei: string }) => {
  const data: Bucket[] = [];
  return (
    <BarChart
      width={150}
      height={40}
      data={data}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
    >
      <Bar dataKey="alive" fill="#8884d8" />
    </BarChart>
  );
}


const calculateStartAt = (offsetDays: number): Date => {
  const bucketSize = 15 * 60 * 1000;  // 15 mins
  const oneDay = 24 * 60 * 60 * 1000;
  const currentBucketTs = Math.floor(new Date().getTime() / bucketSize) * bucketSize;

  return new Date(currentBucketTs - offsetDays * oneDay);
}

const normalizeBuckets = (buckets: Bucket[]): Bucket[] => {
  return buckets.map((bucket) => ({
    key: bucket.key,
    packetCount: bucket.packetCount > 0 ? 1 : 0,
    averageLagMs: bucket.averageLagMs,
    outOfOrderCount: bucket.outOfOrderCount,
  }));
};


interface Bucket {
  key: string;
  packetCount: number;
  averageLagMs: number;
  outOfOrderCount: number;
}

interface DeviceStatus {
  imei: string;
  startAt: Date;
  endAt: Date;
  buckets: Bucket[];
}

const getDeviceStatusQueryFn = async (imei: string, startAt: Date): Promise<DeviceStatus | null> => {
  const url = `https://device-status-service.k8testing.shieldgps.co/devices/${imei}?startAt=${startAt.toISOString()}`;

  const response = await axios.get<DeviceStatus>(url);
  return response.data;
}

export const DeviceStatusTinyLineChart = ({ deviceImei }: { deviceImei: string }) => {
  const startAt = calculateStartAt(1);

  const { data, isLoading } = useQuery(
    ['device-status', deviceImei, startAt],
    () => getDeviceStatusQueryFn(deviceImei, startAt),
    {},
  );

  const normalizedBuckets: Bucket[] = normalizeBuckets(data?.buckets || []);

  if (isLoading) {
    return (<></>);
  }

  return (
    <LineChart
      width={140}
      height={30}
      data={normalizedBuckets}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
    >
      <Line type="monotone" dataKey="packetCount" stroke="#8884d8" strokeWidth={2} dot={false} />
    </LineChart>
  );
}

const getDeviceEventsQuery = async ({ imei, next }: { imei: String, next?: String }): Promise<GetDeviceEventsResponse | null> => {

  let url = `/admin/user-search/devices/${imei}/events`;
  if (next) {
    url = `/admin/user-search/devices/${imei}/events?next=${next}`;
  }

  const response = await ShieldGPSAxiosClient.get<GetDeviceEventsResponse>(url);
  return response.data;
};

const DeviceEventRow = ({ event }: { event: DeviceEvent }) => {
  return (
    <CondensedRow>
      <div style={{ padding: '10px', paddingRight: '20px' }}>
        <DeviceEventIcon eventType={event.type} />
      </div>
      <SummaryContainer>
        <Text>
          {event.type}
        </Text>
      </SummaryContainer>
      <SmallText>
        {formatAsDateAndTime(event.eventAt || event.createdAt)}
      </SmallText>
    </CondensedRow>
  );
}

export const DeviceCommandDirectionIcon = ({ direction }: { direction: string }) => {
  switch (direction) {
    case 'incoming':
      return <ArrowCircleLeftOutlinedIcon style={{ color: 'green' }} fontSize="small" />;
    case 'outgoing':
      return <ArrowCircleRightOutlinedIcon style={{ color: 'red' }} fontSize="small" />;
    default:
      return <QuestionMarkIcon color="primary" fontSize="small" />;
  }
};

export const DeviceCommandMediumIcon = ({ medium }: { medium: string }) => {
  switch (medium) {
    case 'sms':
      return <FormatQuoteOutlinedIcon style={{ color: '#7b7b7b' }} fontSize="small" />;
    case 'data':
      return <MemoryOutlinedIcon style={{ color: '#7b7b7b' }} fontSize="small" />;
    default:
      return <QuestionMarkIcon color="primary" fontSize="small" />;
  }
};

export const DeviceCommandStatusIcon = ({ status }: { status: string }) => {
  switch (status) {
    case 'received':
      return <CheckOutlinedIcon style={{ color: '#006908' }} fontSize="small" />;
    default:
      return <QuestionMarkIcon style={{ color: '#7b7b7b' }} fontSize="small" />;
  }
};

export const DeviceCommandRow = ({ command, onClick }: { command: DeviceCommand, onClick: () => void }) => {
  const clickable = command.direction === 'outgoing';
  return (
    <CondensedRow clickable={clickable} onClick={clickable ? onClick : undefined}>
      <div style={{ padding: '10px', paddingRight: '20px' }}>
        <DeviceCommandDirectionIcon direction={command.direction} />
      </div>
      <SummaryContainer>
        <DeviceCommandBlock>{command.command}</DeviceCommandBlock>
      </SummaryContainer>
      <FixedWidth>
        <span>
          {formatAsDate(command.createdAt)}
        </span>
        <span>
          {formatAsTime(command.createdAt)}
        </span>
        <CommandStatusIcons>
          <DeviceCommandStatusIcon status={command.status} />
          <DeviceCommandMediumIcon medium={command.medium} />
        </CommandStatusIcons>
      </FixedWidth>
    </CondensedRow>
  )
};

const DeviceEventIcon = ({ eventType }: { eventType: String }) => {
  switch (eventType) {
    case 'trip_started': {
      return <PlayCircleOutlineRoundedIcon color="primary" fontSize="small" />;
    }
    case 'trip_ended': {
      return <StopCircleOutlinedIcon color="primary" fontSize="small" />;
    }
    case 'speeding': {
      return <SpeedRoundedIcon color="primary" fontSize="small" />;
    }
    case 'harsh_driving': {
      return <TaxiAlertOutlinedIcon color="primary" fontSize="small" />;
    }
    case 'activated': {
      return <AddCircleOutlineOutlinedIcon color="primary" fontSize="small" />;
    }
    case 'deactivated': {
      return <RemoveCircleOutlineOutlinedIcon color="primary" fontSize="small" />;
    }
    default: {
      return <QuestionMarkOutlinedIcon color="primary" fontSize="small" />;
    }
  }
}


export const DeviceEventsSummary = ({ device }: { device: Device }) => {
  const { data, isLoading } = useQuery(
    ['device-device', device.imei],
    () => getDeviceEventsQuery({ imei: device.imei }),
    {},
  );

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (!data || !data.data || data.data.length === 0) {
    return (
      <div>No events.</div>
    );
  }

  const events = data.data;

  return (
    <div>
      {events.map((event) => (
        <DeviceEventRow key={event.id} event={event} />
      ))}
    </div>
  );
}

export interface CodeBlockProps {
  code: string;
}

export const CodeBlock: React.FC<CodeBlockProps> = ({ code }) => {
  const codeString = JSON.stringify(code, null, 2);

  return (
    <Paper style={{ padding: '8px', backgroundColor: '#f5f5f5' }} elevation={0}>
      <Typography
        component="pre"
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
          fontFamily: '"Roboto Mono", "Lucida Console", Monaco, monospace', // Monospace font for code-like appearance
          fontSize: '0.7rem' // Smaller font size
        }}
      >
        {codeString}
      </Typography>
    </Paper>
  );
};


interface DevicePositionDescriptionProps {
  device: Device;
};

/**
 * Renders a text description of the device's latitude/longitude via reverse geocoding.
 * Displays a loading indicator while fetching the address.
 *
 * @param param0 The device object containing the last position
 * @returns A text description or loading indicator
 */
export const DevicePositionDescription: React.FC<DevicePositionDescriptionProps> = ({ device }) => {
  const [deviceLastPositionDesc, setDeviceLastPositionDesc] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!device?.lastPosition) {
      setDeviceLastPositionDesc(null);
      setLoading(false); // Stop loading if there is no position
      return;
    }

    setLoading(true);

    ShieldGPSAxiosClient.post('/admin/user-search/reverse-geocode', {
      latitude: device.lastPosition.latitude,
      longitude: device.lastPosition.longitude,
    })
      .then((response) => {
        if (response?.data?.address) {
          setDeviceLastPositionDesc(response.data.address);
        } else {
          setDeviceLastPositionDesc('Unknown location');
        }
      })
      .catch(() => {
        setDeviceLastPositionDesc('Error retrieving location');
      })
      .finally(() => {
        setLoading(false); // Stop loading after the request completes
      });

    return () => setDeviceLastPositionDesc(null);
  }, [device?.lastPosition]);

  return (
    <>
      {loading ? (
        <CircularProgress size={14} />
      ) : (
        <MapLink
          label={deviceLastPositionDesc || 'N/A'}
          latitude={device.lastPosition?.latitude || 0}
          longitude={device.lastPosition?.longitude || 0}
        />
      )}
    </>
  );
};

interface MapLinkProps {
  label: string;
  latitude: number;
  longitude: number;
}

export const MapLink: React.FC<MapLinkProps> = ({ label, latitude, longitude }) => {
  const handleOpenMap = () => {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <span>
      <span style={{ marginRight: '6px' }}>{label}</span>
      <IconButton
        onClick={handleOpenMap}
        aria-label="Open in Google Maps"
        size="small"
        color="default"
        sx={{
          padding: '0px',
          marginLeft: '0px',
          cursor: 'pointer',
          color: 'default',
          '&:hover': {
            color: 'blue', // Change this to the color of your choice
          }
        }}
      >
        <OpenInNewOutlinedIcon fontSize='small' />
      </IconButton>
    </span>
  );
};