import { Device } from '../../models/Device';

import { Image } from '../Common';

import ht1Small from '../../images/products/ht1/ht1-1-200x200.png';
import ht1Medium from '../../images/products/ht1/ht1-1-400x400.png';
import ht1Large from '../../images/products/ht1/ht1-1-1000x1000.png';

import ob22Small from '../../images/products/ob22/ob22-1-200x200.png';
import ob22Medium from '../../images/products/ob22/ob22-1-400x400.png';

import at1Small from '../../images/products/at1/at1-1-200x200.png';
import at1Medium from '../../images/products/at1/at1-1-400x400.png';
import at1Large from '../../images/products/at1/at1-1-1000x1000.png';

import at2Small from '../../images/products/at2/at2-1-200x200.png';
import at2Medium from '../../images/products/at2/at2-1-400x400.png';
import at2Large from '../../images/products/at2/at2-1-1000x1000.png';

import gv500maSmall from '../../images/products/gv500ma/gv500ma-1-200x200.png';
import gv500maMedium from '../../images/products/gv500ma/gv500ma-1-400x400.png';
import gv500maLarge from '../../images/products/gv500ma/gv500ma-1-1000x1000.png';

import fmt100Small from '../../images/products/fmt100/fmt100-1-200x200.png';
import fmt100Medium from '../../images/products/fmt100/fmt100-1-400x400.png';
import fmt100Large from '../../images/products/fmt100/fmt100-1-1000x1000.png';

import g1cSmall from '../../images/products/g1c/g1c-1-200x200.png';
import g1cMedium from '../../images/products/g1c/g1c-1-400x400.png';
import g1cLarge from '../../images/products/g1c/g1c-1-1000x1000.png';

import placeholderSmall from '../../images/products/placeholder-200x200.png';
import placeholderMedium from '../../images/products/placeholder-400x400.png';


export interface DeviceImageCollection { 
  small: any;
  medium: any;
  large: any;
}


const DEVICE_IMAGE_MAP: Record<string, DeviceImageCollection> = {
  'HVT001': {
    small: ht1Small,
    medium: ht1Medium,
    large: ht1Large,
  },
  'OB22': {
    small: ob22Small,
    medium: ob22Medium,
    large: null,
  },
  'AT1': {
    small: at1Small,
    medium: at1Medium,
    large: at1Large,
  },
  'ATA': {
    small: at2Small,
    medium: at2Medium,
    large: at2Large,
  },
  'GV500MA': {
    small: gv500maSmall,
    medium: gv500maMedium,
    large: gv500maLarge,
  },
  'FMT100': {
    small: fmt100Small,
    medium: fmt100Medium,
    large: fmt100Large,
  },
  'G1C': {
    small: g1cSmall,
    medium: g1cMedium,
    large: g1cLarge,
  },
  'unknown': {
    small: placeholderSmall,
    medium: placeholderMedium,
    large: null,
  },
};

export const DeviceImage = ({ deviceModel, size }: { deviceModel: string, size: number }) => {

  let imageCollection: DeviceImageCollection = DEVICE_IMAGE_MAP[deviceModel || 'unknown'] || DEVICE_IMAGE_MAP['unknown'];

  let image = imageCollection.small;
  if (size > 200 && size <= 400 && imageCollection.medium) {
    image = imageCollection.medium;
  } else if (size > 400 && imageCollection.large) {
    image = imageCollection.large;
  }
  return <Image image={image} size={size} alt={deviceModel || 'unknown'} />
}
