import CircularProgress from '@mui/material/CircularProgress';
import {
  Link
} from "react-router-dom";
import styled from 'styled-components';
import { BatteryIndicator } from '../../components/BatteryIndicator';
import {
  BiggestText,
  Box,
  ClickToCopy,
  DeviceICCIDLink,
  DevicePositionDescription,
  FieldDescription,
  SectionHeaderText,
  StatusIndicator,
  Text,
  capitalizeFirstLetter,
  formatAsDate,
  formatAsFromNow
} from '../../components/Common';
import { DeviceChargingIndicator } from '../../components/DeviceChargingIndicator';
import { DeviceConfigSyncStateIndicator } from '../../components/DeviceConfigSyncStateIndicator';
import { DeviceSignalIndicator } from '../../components/DeviceSignalIndicator';
import { DeviceBatteryModeTag } from '../../components/device/DeviceBatteryModeTag';
import { DeviceImage } from '../../components/device/DeviceImage';
import { DeviceStatusTag } from '../../components/device/DeviceStatusTag';
import { useDeviceOdometer } from '../../hooks/useDeviceOdometer';
import { Device, deviceBatteryMode } from '../../models/Device';

const AlignedSpan = styled.span`
  display: flex;
  gap: 4px;
`;

const DeviceDataQuota = ({ device }: { device: Device }) => {
  const { dataQuotaBytesUsed, dataQuotaBytesTotal } = device;

  const mbUsed = dataQuotaBytesUsed ? (dataQuotaBytesUsed / 1024 / 1024).toFixed(1) : '0';
  const mbTotal = dataQuotaBytesTotal ? (dataQuotaBytesTotal / 1024 / 1024).toFixed(0) : '--';

  return (<span>{mbUsed} / {mbTotal} MB</span>);
}

const DeviceSMSQuota = ({ device }: { device: Device }) => {
  const { smsQuotaUsed, smsQuotaTotal } = device;

  const used = smsQuotaUsed ? smsQuotaUsed : '0';
  const total = smsQuotaTotal ? smsQuotaTotal : '--';

  return (<span>{used} / {total}</span>);
}

const toMb = (bytes: number | undefined) => {
  if (!bytes) {
    return '0 MB';
  }

  const valueMb = (bytes / 1024 / 1024).toFixed(0);
  return `${valueMb} MB`;
}

const deviceBattery = (device?: Device | null): number | null => {
  return device?.battery || device?.lastPosition?.battery || null;
}

/**
 * Renders an info panel that describes all key properties of a given Device.
 */
export const DeviceProperties = ({ device }: { device: Device | undefined | null }) => {
  const activeSubscription = device && device.deviceSubscriptions.find(sub => sub.state === "active");
  
  const { data: deviceOdometer, isLoading: isDeviceOdometerLoading } = useDeviceOdometer(device?.imei || '');

  const batteryMode = deviceBatteryMode(device);

  return (
    <div>
      <Box>
        <BiggestText>{device?.name}</BiggestText>
        <ClickToCopy copyContent={device?.imei || ''} >
          <Text>{device?.imei}</Text>
        </ClickToCopy>
        { device ? (<DeviceStatusTag device={device} />) : null}
      </Box>

      <Box>
        <DeviceImage deviceModel={device?.model || 'unknown'} size={250} />
      </Box>

      <Box>
        <SectionHeaderText>Details</SectionHeaderText>
        <FieldDescription name="Owner">
          <ClickToCopy copyContent={device?.owner?.email || ''} >
           <Link to={`/users/${device?.owner?.id}`}>{device?.owner?.email}</Link>
          </ClickToCopy>
        </FieldDescription>
        <FieldDescription name="Make" value={`${device?.make}`} />
        <FieldDescription name="Model" value={`${device?.model}`} />
        { deviceBattery(device) != null ? (
          <FieldDescription name="Battery">
            <AlignedSpan>
              <BatteryIndicator percent={deviceBattery(device) || -1} />
              { device?.model === 'AT1' && <DeviceChargingIndicator charging={device?.charging || false} /> }
              { batteryMode && <DeviceBatteryModeTag batteryMode={batteryMode} /> }
            </AlignedSpan>
          </FieldDescription>
          ) : null}
        <FieldDescription name="Signal Strength">
        { (device?.model === 'AT1' && device.signal) ? (
          <DeviceSignalIndicator signal={device.signal || false} withDescription />
        ) : (
          "N/A"
        )}
        </FieldDescription>
        <FieldDescription name="Config Sync Status">
          <AlignedSpan>
            <DeviceConfigSyncStateIndicator syncState={device?.deviceConfigSyncState} />
            {capitalizeFirstLetter(device?.deviceConfigSyncState) || 'N/A'}
          </AlignedSpan>
        </FieldDescription>
        <FieldDescription name="Platform">
          {device?.owner?.platform === 'tracksolid' ? (
            <StatusIndicator label="Tracksolid" isSuccess={device.tracksolidSynced} />
          ) : (
            <span>
              {capitalizeFirstLetter(device?.owner?.platform )}
            </span>
          )}
        </FieldDescription>
        <FieldDescription name="Last Location">
          { device && <DevicePositionDescription device={device} /> }
        </FieldDescription>
        <FieldDescription name="Mileage (miles)">
          {isDeviceOdometerLoading ? <CircularProgress size={14} /> : (
            <span>{deviceOdometer?.mileageMiles.toFixed(2)}</span>
          )}
        </FieldDescription>
        <FieldDescription name="Active Since" value={formatAsDate(activeSubscription?.createdAt)} />
        <FieldDescription name="Last Seen" value={formatAsFromNow(device?.lastseen)} />
        <FieldDescription name="SIM Provider" value={device?.simProvider} />
        <FieldDescription name="ICCID" value={device?.iccid}>
          <DeviceICCIDLink device={device} />
        </FieldDescription>
        <FieldDescription name="SIM Status" value={device?.simStatus || 'Unknown'} />
        <FieldDescription name="Data Quota">
          <DeviceDataQuota device={device!} />
        </FieldDescription>
        <FieldDescription name="SMS Quota">
          <DeviceSMSQuota device={device!} />
        </FieldDescription>
        <FieldDescription name="SIM Expires At">
          <StatusIndicator label={formatAsDate(device?.simExpiresAt) || 'N/A'} isSuccess={device?.simExpiresAt  ? new Date(device?.simExpiresAt) > new Date() : true} />
        </FieldDescription>
        <FieldDescription name="SIM Last Refreshed At" value={formatAsFromNow(device?.simStatusLastRefreshedAt)} />
        <FieldDescription name="Total Storage" value={toMb(device?.storageUsedBytes)} />
      </Box>
    </div>
  );
}





