import moment from 'moment';
import {
  useNavigate,
} from "react-router-dom";
import styled from 'styled-components';
import { Device, deviceBattery, deviceBatteryMode } from '../../models/Device';

import { BatteryIndicator } from '../../components/BatteryIndicator';
import {
  DeviceStatusTinyLineChart,
  Text,
} from '../../components/Common';
import { DeviceImage } from '../../components/device/DeviceImage';
import { DeviceStatusTag } from '../../components/device/DeviceStatusTag';
import { DeviceChargingIndicator } from '../DeviceChargingIndicator';
import { DeviceConfigSyncStateIndicator } from '../DeviceConfigSyncStateIndicator';
import { DeviceSignalIndicator } from '../DeviceSignalIndicator';
import { DeviceBatteryModeTag } from './DeviceBatteryModeTag';

const DeviceRowContainer = styled.div`
  display: flex;
  padding: 10px 5px 10px 5px;
  align-items: center;
  &:first-child {
    border-top: #d8d8d8 1px solid;
  }
  border-bottom: #d8d8d8 1px solid;
  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
`;

const DeviceTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeviceTags = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const DeviceInfoMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-start;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 5px;
`;

export const DeviceRow = ({ device }: { device: Device }) => {
  const navigate = useNavigate();

  const batteryMode = deviceBatteryMode(device);

  return (
    <DeviceRowContainer onClick={() => navigate(`/devices/${device.imei}`)}>
      <DeviceImage deviceModel={device?.model} size={60} />
      <DeviceInfoMain>
        <DeviceTitleRow>
          <Text><strong>{device.imei} - {device.name}</strong></Text>
        </DeviceTitleRow>
        <Text>{device.plan?.name} / Billed {device.plan?.interval} @ {device.plan?.price} {device.plan?.currency}</Text>
        <DeviceTags>
          <DeviceStatusTag device={device} />
          <DeviceConfigSyncStateIndicator syncState={device?.deviceConfigSyncState} />
          { ['AT1'].includes(device.model) && batteryMode && <DeviceBatteryModeTag batteryMode={batteryMode} /> }
          { deviceBattery(device) != null ? <BatteryIndicator percent={deviceBattery(device) || -1} /> : null }
          { ['AT1'].includes(device.model) && <DeviceChargingIndicator charging={device.charging || false} /> }
          { ['AT1'].includes(device.model) && device.signal && <DeviceSignalIndicator signal={device.signal} /> }
        </DeviceTags>
      </DeviceInfoMain>
      <div>
        <DeviceStatusTinyLineChart deviceImei={device.imei} />
        <Text style={{ fontSize: '8pt', paddingTop: '10px', textAlign: 'right'}}>{device.lastseen ? moment(device.lastseen).fromNow() : 'Never seen'}</Text>
      </div>
    </DeviceRowContainer>
  );
};

